import { createSlice }    from '@reduxjs/toolkit';
import _                  from 'lodash';
import { v4 as uuidv4 }   from 'uuid';
import { createSelector } from '@reduxjs/toolkit';
import Defaults           from 'constants/Defaults';

const exampleShortcuts = Defaults.shortcuts;
const exampleHolds     = Defaults.holds;

const prepareShortCuts = (shortcuts) => shortcuts.map((shortcut) => {
    const keys = [...shortcut.keys].sort();

    return {
        ...shortcut,
        keys: keys,
        sort: keys.join(''),
    };
});

const initialState = {
    open:      false,
    shortcuts: {
        open:      false,
        shortcuts: prepareShortCuts(exampleShortcuts),
    },
    holds:     {
        open:  false,
        holds: exampleHolds,
    },
};
const reducers     = {
    openSettings:           (state) => {
        state.open = true;
    },
    closeSettings:          (state) => {
        state.open = false;
    },
    toggleSettingsShortcut: (state) => {
        state.shortcuts.open = !state.shortcuts.open;

        if (state.shortcuts.open) {
            state.holds.open = false;
        }
    },
    toggleSettingsHold:     (state) => {
        state.holds.open = !state.holds.open;

        if (state.holds.open) {
            state.shortcuts.open = false;
        }
    },
    deleteShortcut:         (state, action) => {
        _.remove(state.shortcuts.shortcuts, { id: action.payload.id });
    },
    deleteHold:             (state, action) => {
        _.remove(state.holds.holds, { id: action.payload.id });
    },
    setNewShortcut:         (state, action) => {
        const keys = [...action.payload.keys];

        if (keys.length) {
            const sortedKeys          = keys.sort();
            state.shortcuts.shortcuts = _.uniqBy([
                ...state.shortcuts.shortcuts,
                {
                    id:   uuidv4(),
                    keys: sortedKeys,
                    sort: sortedKeys.join(''),
                },
            ], 'sort');
        }
    },
    setNewHold:             (state, action) => {
        const { key } = action.payload;

        if (key) {
            state.holds.holds = _.uniqBy([
                ...state.holds.holds,
                {
                    id: uuidv4(),
                    key,
                },
            ], 'key');
        }
    },
    resetShortcuts:         (state) => {
        state.shortcuts.shortcuts = prepareShortCuts(exampleShortcuts);
    },
    resetHolds:             (state) => {
        state.holds.holds = exampleHolds;
    },
    exportSettings:         (state) => state,
    parseSettings:          (state) => state,
    importSettings:         (state, action) => {
        state.shortcuts.shortcuts = action.payload.shortcuts;
        state.holds.holds         = action.payload.holds;
    },
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers,
});

export const { openSettings }           = settingsSlice.actions;
export const { closeSettings }          = settingsSlice.actions;
export const { toggleSettingsShortcut } = settingsSlice.actions;
export const { toggleSettingsHold }     = settingsSlice.actions;
export const { deleteShortcut }         = settingsSlice.actions;
export const { deleteHold }             = settingsSlice.actions;
export const { setNewShortcut }         = settingsSlice.actions;
export const { setNewHold }             = settingsSlice.actions;
export const { resetShortcuts }         = settingsSlice.actions;
export const { resetHolds }             = settingsSlice.actions;
export const { exportSettings }         = settingsSlice.actions;
export const { parseSettings }          = settingsSlice.actions;
export const { importSettings }         = settingsSlice.actions;
export const settingsOpen               = (state) => state.settings.open;
export const settingsShortcutOpen       = (state) => state.settings.shortcuts.open;
export const settingsHoldOpen           = (state) => state.settings.holds.open;
export const settingsShortcutsMemory    = (state) => state.settings.shortcuts.shortcuts;
export const settingsHoldsMemory        = (state) => state.settings.holds.holds;
export const settingsShortcuts          = createSelector([settingsShortcutsMemory], (shortcuts) => [...shortcuts].reverse());
export const settingsHolds              = createSelector([settingsHoldsMemory], (holds) => [...holds].reverse());
export const getSettings                = (state) => (
    {
        shortcuts: state.settings.shortcuts.shortcuts,
        holds:     state.settings.holds.holds,
    }
);

export default settingsSlice.reducer;
