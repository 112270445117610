import React            from 'react';
import SimpleKeyboard   from 'react-simple-keyboard';
import { useSelector }  from 'react-redux';
import KeyTranslator    from 'helper/KeyTranslator.js';
import { useRef }       from 'react';
import { getShortcut }  from 'store/reducers/quest.js';
import { getQuestHold } from 'store/reducers/quest.js';

import styles from './styles.module.scss';

const Keyboard = () => {
    const reference = useRef();
    const shortcut  = useSelector(getShortcut);
    const hold      = KeyTranslator.keyToString(useSelector(getQuestHold)?.key) || '{Meta}';
    const highlight = KeyTranslator.keysToString(shortcut?.keys) || '{Meta}';

    return (
        <div className={styles.keyboard}>
            <SimpleKeyboard
                keyboardRef={ref => reference.current = ref}
                buttonTheme={[
                    {
                        class:   'highlight',
                        buttons: highlight,
                    },
                    {
                        class:   'hold',
                        buttons: hold,
                    },
                ]}
                layout={{
                    default: [
                        '{escape} F1 F2 F2 F3 F4 F5 F6 F7 F8 F9 F10 F11 F12',
                        '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
                        '{tab} Q W E R T Z U I O P \u00DC +',
                        '{lock} A S D F G H J K L \u00D6 \u00C4 # {enter}',
                        '{shift} < Y X C V B N M , . - {shift}',
                        '{space}',
                    ],
                }}
            />
        </div>
    );
};

export default Keyboard;
