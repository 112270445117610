import React                      from 'react';
import { useSelector }            from 'react-redux';
import { settingsOpen }           from 'store/reducers/settings';
import { useDispatch }            from 'react-redux';
import { closeSettings }          from 'store/reducers/settings';
import { toggleSettingsShortcut } from 'store/reducers/settings';
import { toggleSettingsHold }     from 'store/reducers/settings';
import Shortcuts                  from './Shortcuts';
import Holds                      from './Holds';
import { useRef }                 from 'react';
import classNames                 from 'classnames';
import { settingsShortcutOpen }   from 'store/reducers/settings';
import { settingsHoldOpen }       from 'store/reducers/settings';
import { parseSettings }          from 'store/reducers/settings';
import { exportSettings }         from 'store/reducers/settings';
import FileInput                  from '../FileInput';

import styles from './styles.module.scss';

const Settings = () => {
    const dispatch          = useDispatch();
    const inputReference    = useRef();
    const onClose           = () => dispatch(closeSettings());
    const onToggleShortcuts = () => dispatch(toggleSettingsShortcut());
    const onToggleHolds     = () => dispatch(toggleSettingsHold());
    const onExportSettings  = () => dispatch(exportSettings());
    const onImportSettings  = (event) => dispatch(parseSettings({ file: event.target.files[0] }));
    const open              = !!useSelector(settingsOpen);
    const isShortcutActive  = !!useSelector(settingsShortcutOpen);
    const isHoldActive      = !!useSelector(settingsHoldOpen);

    const onFileChange = (event) => {
        onImportSettings(event);

        inputReference.current.value = null;
    };

    const onImportClick = () => {
        inputReference.current.click();
    };

    return (
        <dialog
            className={styles.settings}
            open={open}
        >
            <div className={styles.navigation}>
                <button
                    onClick={onToggleShortcuts}
                    className={classNames(
                        {
                            [styles.active]: isShortcutActive,
                        },
                    )}
                >
                    Edit Shortcuts
                </button>
                <button
                    onClick={onToggleHolds}
                    className={classNames(
                        {
                            [styles.active]: isHoldActive,
                        },
                    )}
                >
                    Edit Holds
                </button>
                <button onClick={onImportClick}>
                    Import
                </button>
                <FileInput
                    ref={inputReference}
                    onChange={onFileChange}
                />
                <button onClick={onExportSettings}>
                    Export
                </button>
                <button onClick={onClose}>
                    Close
                </button>
            </div>
            <div className={styles.subsettingsContainer}>
                <Holds />
                <Shortcuts />
            </div>
        </dialog>
    );
};

export default Settings;
