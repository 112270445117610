import { createSlice } from '@reduxjs/toolkit';
import _               from 'lodash';
import moment          from 'moment';

const initialHold  = {
    key:   null,
    start: null,
};
const initialState = {
    start:     null,
    shortcuts: [],
    holds:     [],
    hold:      initialHold,
};
const reducers     = {
    questFinished: (state) => {
        state.start = null;
    },
    nextShortcut:  (state) => {
        state.shortcuts.shift();
    },
    nextHold:      (state) => {
        state.holds.shift();
    },
    startHold:     (state, action) => {
        state.hold = {
            key:   action.payload.hold,
            start: moment().toISOString(),
        };
    },
    stopHold:      (state) => {
        state.hold = initialHold;
    },
    restartQuest:  (state, action) => (
        {
            start:     moment().toISOString(),
            shortcuts: _.shuffle(action.payload.shortcuts),
            holds:     _.shuffle(action.payload.holds),
            hold:      initialHold,
        }
    ),
    stopQuest:     () => initialState,
};

const questSlice = createSlice({
    name: 'quest',
    initialState,
    reducers,
});

export const { questFinished } = questSlice.actions;
export const { nextShortcut }  = questSlice.actions;
export const { nextHold }      = questSlice.actions;
export const { startHold }     = questSlice.actions;
export const { stopHold }      = questSlice.actions;
export const { restartQuest }  = questSlice.actions;
export const { stopQuest }     = questSlice.actions;
export const getQuest          = (state) => state.quest;
export const getHold           = (state) => state.quest.hold.key;
export const getHoldStart      = (state) => state.quest.hold.start;
export const getQuestStart     = (state) => state.quest.start;
export const getShortcut       = (state) => state.quest.shortcuts[0];
export const getQuestShortcuts = (state) => state.quest.shortcuts;
export const getQuestHold      = (state) => state.quest.holds[0];
export const questActive       = (state) => !!state.quest.shortcuts.length;

export default questSlice.reducer;
