import React            from 'react';
import { useDispatch }  from 'react-redux';
import { openSettings } from 'store/reducers/settings';

const Settings = () => {
    const dispatch = useDispatch();
    const onOpen   = () => dispatch(openSettings());

    return (
        <button onClick={onOpen}>
            Settings
        </button>
    );
};

export default Settings;
