export default class KeyTranslator {
    static keyToString = (key) => KeyTranslator.toKeyboard(key);

    static keysToString = (keys) => keys?.map((key) => KeyTranslator.toKeyboard(key)).join(' ');

    static toKeyboard = (key) => {
        switch (key) {
            case 'SHIFT':
                return '{shift}';
            case 'BACKSPACE':
                return '{bksp}';
            case 'ENTER':
                return '{enter}';
            case 'ESCAPE':
                return '{escape}';
            case 'META':
                return '{meta}';
            case ' ':
                return '{space}';
            default:
                return key;
        }
    };

    static toStore = (key) => {
        const lowerCase = key.toUpperCase();

        switch (lowerCase) {
            case '\u00B0':
                return '^';
            case 'DEAD':
                return '^';
            case '!':
                return '1';
            case '"':
                return '2';
            case '\u00A7':
                return '3';
            case '$':
                return '4';
            case '%':
                return '5';
            case '&':
                return '6';
            case '/':
                return '7';
            case '(':
                return '8';
            case ')':
                return '9';
            case '=':
                return '0';
            case '\u00DF':
                return '?';
            case '\u00B4':
                return '`';
            case '+':
                return '*';
            case '<':
                return '>';
            case ',':
                return ';';
            case '.':
                return ':';
            case '-':
                return '_';
            default:
                return lowerCase;
        }
    };
}
