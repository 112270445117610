import { restartQuest }   from './reducers/quest';
import { setNewShortcut } from './reducers/settings';
import { setNewHold }     from './reducers/settings';

const subscriber = (store) => {
    const { getState } = store;

    return (next) => (action) => {
        const prevState = getState();

        if (action.type === restartQuest().type) {
            action.payload = {
                shortcuts: prevState.settings.shortcuts.shortcuts,
                holds:     prevState.settings.holds.holds,
            };
        }

        if (action.type === setNewShortcut().type) {
            action.payload = {
                keys: prevState.keyboard,
            };
        }

        if (action.type === setNewHold().type) {
            action.payload = {
                key: prevState.keyboard[0],
            };
        }

        return next(action);
    };
};

export default subscriber;
