import React           from 'react';
import Start           from './Start';
import Stop            from './Stop';
import Settings        from './Settings';
import { useSelector } from 'react-redux';
import { questActive } from '../../store/reducers/quest';

import styles from './styles.module.scss';

const Control = () => {
    const isQuestActive = useSelector(questActive);

    return (
        <div className={styles.control}>
            <Start questActive={isQuestActive} />
            <Stop questActive={isQuestActive} />
            <Settings />
        </div>
    );
};

export default Control;
