import React                    from 'react';
import { useSelector }          from 'react-redux';
import { settingsShortcutOpen } from 'store/reducers/settings';
import { useDispatch }          from 'react-redux';
import { settingsShortcuts }    from 'store/reducers/settings';
import { setNewShortcut }       from 'store/reducers/settings';
import { getKeyboard }          from 'store/reducers/keyboard';
import { resetShortcuts }       from 'store/reducers/settings';
import KeyTranslator            from 'helper/KeyTranslator';
import Shortcut                 from 'components/Shortcut';
import { deleteShortcut }       from 'store/reducers/settings';
import Defaults                 from 'constants/Defaults';
import Compare                  from 'helper/Compare';

import styles from '../styles.module.scss';

const Shortcuts = () => {
    const dispatch         = useDispatch();
    const onDelete         = (id) => () => dispatch(deleteShortcut({ id }));
    const onSetShortcut    = () => dispatch(setNewShortcut());
    const onResetShortcuts = () => dispatch(resetShortcuts());
    const open             = !!useSelector(settingsShortcutOpen);
    const shortcuts        = useSelector(settingsShortcuts);
    const keyboard         = useSelector(getKeyboard);
    const toSet            = KeyTranslator.keysToString(keyboard);

    const renderToSet = () => {
        if (toSet) {
            return toSet;
        }

        return '[Press Key]';
    };

    const renderReset = () => {
        if (!Compare.shortcutsEqual(shortcuts, Defaults.shortcuts)) {
            return (
                <button onClick={onResetShortcuts}>
                    Reset
                </button>
            );
        }
    };

    return (
        <dialog
            className={styles.subsettings}
            open={open}
        >
            <div className={styles.buttons}>
                <button
                    onClick={onSetShortcut}
                    disabled={!keyboard.length}
                >
                    Set shortcut
                    <div className={styles.keys}>
                        {renderToSet()}
                    </div>
                </button>
                {renderReset()}
            </div>
            <div className={styles.shortcuts}>
                {shortcuts.map((shortcut) => (
                    <Shortcut
                        key={`settings-shortcut-keys-${shortcut.id}`}
                        id={shortcut.id}
                        keys={shortcut.keys}
                        onDelete={onDelete(shortcut.id)}
                    />
                ))}
            </div>
        </dialog>
    );
};

export default Shortcuts;
