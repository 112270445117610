import { put }                        from 'redux-saga/effects';
import { select }                     from 'redux-saga/effects';
import _                              from 'lodash';
import { getQuestHold, nextShortcut } from '../reducers/quest';
import { restartQuest }               from '../reducers/quest';
import { newHistory }                 from '../reducers/history';
import { resetHolds }                 from '../reducers/keyboard';
import { questFinished }              from '../reducers/quest';
import moment                         from 'moment';
import { error }                      from '../reducers/alerts';
import { stopQuest }                  from '../reducers/quest';
import { getQuest }                   from '../reducers/quest';
import { getKeyboard }                from '../reducers/keyboard';
import { startHold }                  from '../reducers/quest';
import { getHold }                    from '../reducers/quest';

function* onKeyDown() {
    const keyboard  = [...yield select(getKeyboard)];
    const quest     = yield select(getQuest);
    const hold      = yield select(getHold);
    const questHold = yield select(getQuestHold);

    if (
        quest.holds.length &&
        keyboard.includes(quest.holds[0].key)
    ) {
        const { key } = quest.holds[0];

        _.remove(keyboard, (possibleKey) => possibleKey === key);

        if (hold !== key) {
            yield put(startHold({
                hold: key,
            }));
        }
    }

    if (
        quest.shortcuts.length &&
        (
            !quest.holds.length ||
            (
                hold &&
                hold === questHold.key
            )
        ) &&
        _.isEqual(
            _.sortBy(keyboard),
            _.sortBy(quest.shortcuts[0].keys),
        )
    ) {
        yield put(nextShortcut());
    }
}

function* onKeyUp() {
    // TODO: Count misclick
}

function* onNextShortcut() {
    const quest = yield select(getQuest);

    if (!quest.shortcuts.length) {
        yield put(questFinished({ quest }));
    }
}

function* onRestartQuest() {
    const quest = yield select(getQuest);

    if (!quest.shortcuts.length) {
        yield put(error({ text: 'No Shortcuts found' }));
        yield put(stopQuest());
    } else {
        yield put(resetHolds());
    }
}

function* onQuestFinished(action) {
    const quest   = action.payload.quest;
    const start   = moment(quest.start);
    const end     = moment();
    const history = {
        start:    start.toISOString(),
        end:      end.toISOString(),
        duration: moment.duration(end.diff(start)).asSeconds(),
    };

    yield put(newHistory({ history }));
    yield put(restartQuest());
}

const quests = {
    onKeyDown,
    onKeyUp,
    onNextShortcut,
    onQuestFinished,
    onRestartQuest,
};

export default quests;
