import React             from 'react';
import { useSelector }   from 'react-redux';
import { getQuestStart } from 'store/reducers/quest';
import { useEffect }     from 'react';
import { useState }      from 'react';
import moment            from 'moment';
import { getClock }      from 'store/reducers/clock';

import styles from './styles.module.scss';

const Timer = () => {
    const questStart              = useSelector(getQuestStart);
    const clock                   = useSelector(getClock);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const start = moment(questStart);
        const now   = moment();

        setDuration(moment.duration(now.diff(start)).asSeconds());
    }, [clock, questStart]);

    const renderDuration = () => {
        if (duration) {
            return (
                <div className={styles.time}>
                    <span>
                        Timer
                    </span>
                    {duration.toFixed(0)} s
                </div>
            );
        }
    };

    return (
        <div className={styles.timer}>
            {renderDuration()}
        </div>
    );
};

export default Timer;
