import { useEffect }   from 'react';
import { useDispatch } from 'react-redux';
import { resetHolds }  from 'store/reducers/keyboard';

const WindowListener = () => {
    const dispatch = useDispatch();
    const onFocus  = () => dispatch(resetHolds());

    const listeners = () => {
        window.addEventListener('focus', onFocus);

        return () => {
            window.removeEventListener('focus', onFocus);
        };
    };

    useEffect(listeners);

    return null;
};

export default WindowListener;
