import { put }             from 'redux-saga/effects';
import { select }          from 'redux-saga/effects';
import { getHighscore }    from '../reducers/highscore';
import { newHighscore }    from '../reducers/highscore';
import { resetHighscores } from '../reducers/highscore';
import { stopQuest }       from '../reducers/quest';
import { newHistoryEvent } from '../reducers/history';

function* onNewHistory(action) {
    const highscore = yield select(getHighscore);
    const duration  = action.payload.history.duration;

    if (!highscore || duration < highscore) {
        yield put(newHighscore({ highscore: duration }));
    }
}

function* onShortcutChanged() {
    yield put(stopQuest());
    yield put(newHistoryEvent({ text: 'Settings changed - Highscore deleted' }));
    yield put(resetHighscores());
}

const quests = {
    onNewHistory,
    onShortcutChanged,
};

export default quests;
