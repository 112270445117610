import React      from 'react';
import PropTypes  from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Shortcut = ({ id, keys, onDelete }) => {
    const renderKey = (key) => {
        switch (key) {
            case 'SHIFT':
                return <i className={'fa-regular fa-up'}></i>;
            case 'TAB':
                return <i className={'fa-regular fa-arrow-right-long-to-line'}></i>;
            default:
                return key;
        }
    };

    const renderDelete = () => {
        if (onDelete) {
            return (
                <button
                    onClick={onDelete}
                    className={styles.delete}
                >
                    <i className={'fa-solid fa-trash'}></i>
                </button>
            );
        }
    };

    return (
        <div className={styles.shortcut}>
            {renderDelete()}
            {keys.map((key) => (
                <span
                    key={`quest-${id}-hold-${key}`}
                    className={classNames(
                        styles.key,
                        {
                            [styles.shift]: key === 'SHIFT',
                        },
                    )}
                >
                    {renderKey(key)}
                </span>
            ))}
        </div>
    );
};

Shortcut.propTypes = {
    id:       PropTypes.string,
    keys:     PropTypes.arrayOf(PropTypes.string),
    onDelete: PropTypes.func,
};

export default Shortcut;
