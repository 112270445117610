import React                from 'react';
import { useSelector }      from 'react-redux';
import { getHistorySorted } from 'store/reducers/history';
import moment               from 'moment';

import styles from './styles.module.scss';

const History = () => {
    const history = useSelector(getHistorySorted);

    const renderHistory = (item) => {
        const { text } = item;

        if (text) {
            return text;
        }

        return `${item.duration.toFixed(2)} s - ${moment.duration(moment(item.end).diff(moment())).humanize(true)}`;
    };

    return (
        <div className={styles.history}>
            {history.map((item, index) => (
                <div key={`history-${index}`}>
                    {renderHistory(item)}
                </div>
            ))}
        </div>
    );
};

export default History;
