import React            from 'react';
import { useSelector }  from 'react-redux';
import { getHighscore } from 'store/reducers/highscore';

import styles from './styles.module.scss';

const Highscore = () => {
    const highscore = useSelector(getHighscore);

    const renderHighscore = () => {
        if (highscore) {
            return `Highscore: ${highscore} s`;
        }
    };

    return (
        <div className={styles.highscore}>
            {renderHighscore()}
        </div>
    );
};

export default Highscore;
