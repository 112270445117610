import React     from 'react';
import History   from 'components/History';
import Highscore from 'components/Highscore';
import Quests    from 'components/Quests';
import Keyboard  from 'components/Keyboard';
import Control   from 'components/Control';
import Listener  from 'components/Listener';
import Timer     from 'components/Timer';
import Hold      from 'components/Hold';

import styles from './styles.module.scss';

import 'react-simple-keyboard/build/css/index.css';

const Home = () => {
    return (
        <>
            <div className={styles.home}>
                <div className={styles.quest}>
                    <Hold />
                    <Timer />
                    <Quests />
                </div>
                <div className={styles.homeControl}>
                    <div className={styles.keyboardContainer}>
                        <Keyboard />
                        <div className={styles.highscore}>
                            <Highscore />
                            <History />
                        </div>
                    </div>
                    <Control />
                </div>
            </div>
            <Listener />
        </>
    );
};

export default Home;
