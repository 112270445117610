import { createSlice }    from '@reduxjs/toolkit';
import _                  from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

const initialState = [];
const reducers     = {
    newHistory:      (state, action) => {
        state.push(action.payload.history);

        if (state.length > 10) {
            state.shift();
        }
    },
    newHistoryEvent: (state, action) => {
        const last = state[state.length - 1];

        if (
            last &&
            !last.text
        ) {
            state.push({ text: action.payload.text });

            if (state.length > 10) {
                state.shift();
            }
        }
    },
};

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers,
});

export const { newHistory }      = historySlice.actions;
export const { newHistoryEvent } = historySlice.actions;
export const getHistory          = (state) => state.history;
export const getHistorySorted    = createSelector([getHistory], (history) => _.orderBy(history, ['start'], ['desc']));

export default historySlice.reducer;
