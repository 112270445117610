import React            from 'react';
import { useSelector }  from 'react-redux';
import { getQuestHold } from 'store/reducers/quest';
import { getHoldStart } from 'store/reducers/quest';
import moment           from 'moment/moment';
import { useEffect }    from 'react';
import { getClock }     from 'store/reducers/clock';
import { useState }     from 'react';
import HoldHelper       from 'helper/Hold';
import { nextHold }     from 'store/reducers/quest';
import classNames       from 'classnames';
import { useDispatch }  from 'react-redux';

import styles from './styles.module.scss';

const Hold = () => {
    const dispatch                = useDispatch();
    const hold                    = useSelector(getQuestHold);
    const holdStart               = useSelector(getHoldStart);
    const clock                   = useSelector(getClock);
    const [duration, setDuration] = useState(0);
    const finished                = HoldHelper.isFinished(duration);

    useEffect(() => {
        if (finished) {
            dispatch(nextHold());
        }
    }, [finished]);

    useEffect(() => {
        const start = moment(holdStart);
        const now   = moment();

        setDuration(moment.duration(now.diff(start)).asSeconds());
    }, [clock, holdStart]);

    const renderStart = () => {
        if (holdStart) {
            return (
                <div className={styles.start}>
                    {`${duration.toFixed(0)} s`}
                </div>
            );
        }
    };

    if (hold) {
        return (
            <div
                className={classNames(
                    styles.hold,
                    {
                        [styles.finished]: finished,
                    },
                )}
            >
                <span>Hold:</span>
                {hold?.key}
                {renderStart()}
            </div>
        );
    }
};

export default Hold;
