import { onKeyUp as onKeyUpAction }     from 'store/reducers/keyboard.js';
import { onKeyDown as onKeyDownAction } from 'store/reducers/keyboard.js';
import { useEffect }                    from 'react';
import { resetHolds }                   from 'store/reducers/keyboard.js';
import { useDispatch }                  from 'react-redux';
import { stopHold }                     from 'store/reducers/quest';

const KeyboardListener = () => {
    const dispatch  = useDispatch();
    const onKeyUp   = (event) => dispatch(onKeyUpAction({ key: event.key }));
    const onKeyDown = (event) => dispatch(onKeyDownAction({ key: event.key }));

    const listeners = () => {
        dispatch(resetHolds());
        dispatch(stopHold());
        window.addEventListener('keyup', onKeyUp);
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keyup', onKeyUp);
            window.removeEventListener('keydown', onKeyDown);
        };
    };

    useEffect(listeners);

    return null;
};

export default KeyboardListener;
