import React          from 'react';
import PropTypes      from 'prop-types';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const FileInput = ({ onChange }, ref) => {
    return (
        <div className={styles.input}>
            <input
                ref={ref}
                type={'file'}
                accept={'application/json'}
                onChange={onChange}
            />
        </div>
    );
};

const FileInputRef = forwardRef(FileInput);

FileInput.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default FileInputRef;
