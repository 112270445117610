import React           from 'react';
import { stopQuest }   from 'store/reducers/quest';
import { useDispatch } from 'react-redux';
import PropTypes       from 'prop-types';

const Stop = ({ questActive }) => {
    const dispatch = useDispatch();
    const onStop   = () => dispatch(stopQuest());

    if (questActive) {
        return (
            <button onClick={onStop}>
                Stop
            </button>
        );
    }
};

Stop.propTypes = {
    questActive: PropTypes.bool,
};

export default Stop;
