import React                from 'react';
import { useSelector }      from 'react-redux';
import { useDispatch }      from 'react-redux';
import { getKeyboard }      from 'store/reducers/keyboard';
import KeyTranslator        from 'helper/KeyTranslator';
import { resetHolds }       from 'store/reducers/settings';
import { settingsHolds }    from 'store/reducers/settings';
import { setNewHold }       from 'store/reducers/settings';
import { settingsHoldOpen } from 'store/reducers/settings';
import Shortcut             from 'components/Shortcut';
import { deleteHold }       from 'store/reducers/settings';
import Defaults             from 'constants/Defaults';
import Compare              from 'helper/Compare';

import styles from '../styles.module.scss';

const Holds = () => {
    const dispatch         = useDispatch();
    const onDelete         = (id) => () => dispatch(deleteHold({ id }));
    const onSetHold        = () => dispatch(setNewHold());
    const onResetShortcuts = () => dispatch(resetHolds());
    const open             = !!useSelector(settingsHoldOpen);
    const holds            = useSelector(settingsHolds);
    const keyboard         = useSelector(getKeyboard);
    const toSet            = KeyTranslator.keyToString(keyboard[0]);

    const renderToSet = () => {
        if (toSet) {
            return toSet;
        }

        return '[Press Key]';
    };

    const renderReset = () => {
        if (!Compare.holdsEqual(holds, Defaults.holds)) {
            return (
                <button onClick={onResetShortcuts}>
                    Reset {/*TODO: Preset*/}
                </button>
            );
        }
    };

    return (
        <dialog
            className={styles.subsettings}
            open={open}
        >
            <div className={styles.buttons}>
                <button
                    onClick={onSetHold}
                    disabled={!keyboard.length}
                >
                    Set shortcut
                    <div className={styles.keys}>
                        {renderToSet()}
                    </div>
                </button>
                {renderReset()}
            </div>
            <div className={styles.holds}>
                {holds.map((hold) => (
                    <Shortcut
                        key={`settings-hold-keys-${hold.id}`}
                        id={hold.id}
                        keys={[hold.key]}
                        onDelete={onDelete(hold.id)}
                    />
                ))}
            </div>
        </dialog>
    );
};

export default Holds;
