import { v4 as uuidv4 } from 'uuid';

export default Object.freeze({
    shortcuts: [
        {
            id:   uuidv4(),
            keys: [
                '1',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                '2',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                '3',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                '4',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                '5',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'Q',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'W',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'E',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'R',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'T',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'F',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'G',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'Y',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'X',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'C',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'V',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                '^',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                '>',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'TAB',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '1',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '2',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '3',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '4',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '5',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                'F',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                'G',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                'Y',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                'X',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                'C',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                'V',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '^',
            ],
        },
        {
            id:   uuidv4(),
            keys: [
                'SHIFT',
                '>',
            ],
        },
    ],
    holds:     [
        {
            id:  uuidv4(),
            key: 'W',
        },
        {
            id:  uuidv4(),
            key: 'A',
        },
        {
            id:  uuidv4(),
            key: 'D',
        },
    ],
});
