import { createSlice } from '@reduxjs/toolkit';

const initialState = null;
const reducers     = {
    newHighscore:    (state, action) => action.payload.highscore,
    resetHighscores: () => initialState,
};

const highscoreSlice = createSlice({
    name: 'highscore',
    initialState,
    reducers,
});

export const { newHighscore }    = highscoreSlice.actions;
export const { resetHighscores } = highscoreSlice.actions;
export const getHighscore        = (state) => state.highscore;

export default highscoreSlice.reducer;
