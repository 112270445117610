import { createSlice }  from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import AlertTypes       from '../../components/Alerts/AlertTypes';
import _                from 'lodash';

const initialState = [];
const reducers     = {
    error:   (state, action) => {
        state.push({
            id:   uuidv4(),
            type: AlertTypes.error,
            ...action.payload,
        });
    },
    remove:  (state, action) => {
        _.remove(state, { id: action.payload.id });
    },
    timeout: (state) => {
        state.shift();
    },
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers,
});

export const { error }   = alertsSlice.actions;
export const { remove }  = alertsSlice.actions;
export const { timeout } = alertsSlice.actions;
export const getAlerts   = (state) => state.alerts;

export default alertsSlice.reducer;
