import { configureStore }   from '@reduxjs/toolkit';
import { combineReducers }  from '@reduxjs/toolkit';
import { persistStore }     from 'redux-persist';
import { persistReducer }   from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage              from 'redux-persist/lib/storage';
import alertsReducer        from './reducers/alerts';
import clockReducer         from './reducers/clock';
import highscoreReducer     from './reducers/highscore';
import historyReducer       from './reducers/history';
import questReducer         from './reducers/quest';
import keyboardReducer      from './reducers/keyboard';
import settingsReducer      from './reducers/settings';
import sagas                from './sagas';
import Environment          from 'helper/Environment';
import subscriber           from './subscriber';

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    alerts:    alertsReducer,
    clock:     clockReducer,
    highscore: highscoreReducer,
    history:   historyReducer,
    keyboard:  keyboardReducer,
    quest:     questReducer,
    settings:  settingsReducer,
});

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStore = () => {
    const allMiddlewares = [
        sagaMiddleware,
        subscriber,

    ];
    const store          = configureStore({
        reducer:    persistedReducer,
        devTools:   Environment.isDevelopment(),
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: false,
        }).concat(...allMiddlewares),
    });
    const persistor      = persistStore(store);

    sagaMiddleware.run(sagas.root);

    return {
        store,
        persistor,
    };
};

export default createStore;
