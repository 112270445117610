import { put }     from 'redux-saga/effects';
import Clock       from 'helper/Clock';
import { timeout } from '../reducers/alerts';

function* onAlert() {
    yield Clock.sleep(5);
    yield put(timeout());
}

const alerts = {
    onAlert,
};

export default alerts;
