import React           from 'react';
import PropTypes       from 'prop-types';
import AlertTypes      from '../AlertTypes';
import classNames      from 'classnames';
import { useDispatch } from 'react-redux';
import { remove }      from 'store/reducers/alerts';

import styles from './styles.module.scss';

const Alert = ({ alert }) => {
    const dispatch = useDispatch();
    const onRemove = () => dispatch(remove({ id: alert.id }));

    return (
        <div
            className={classNames(
                styles.alert,
                {
                    [styles.error]: alert.type === AlertTypes.error,
                },
            )}
        >
            {alert.text}
            <button onClick={onRemove}>
                <i className={'fa-solid fa-xmark'}></i>
            </button>
        </div>
    );
};

Alert.propTypes = {
    alert: PropTypes.shape({
        id:   PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.oneOf(Object.values(AlertTypes)),
    }),
};

export default Alert;
