import Alerts             from './alerts';
import Clock              from './clock';
import Highscore          from './highscore';
import Quest              from './quest';
import { all }            from 'redux-saga/effects';
import { error }          from '../reducers/alerts';
import { newHistory }     from '../reducers/history';
import { nextShortcut }   from '../reducers/quest';
import { onKeyDown }      from '../reducers/keyboard';
import { questFinished }  from '../reducers/quest';
import { REHYDRATE }      from 'redux-persist';
import { restartQuest }   from '../reducers/quest';
import { takeLatest }     from 'redux-saga/effects';
import { takeEvery }      from 'redux-saga/effects';
import { tick }           from '../reducers/clock';
import { deleteShortcut } from '../reducers/settings';
import { setNewShortcut } from '../reducers/settings';
import { deleteHold }     from '../reducers/settings';
import { setNewHold }     from '../reducers/settings';
import { exportSettings } from '../reducers/settings';
import Settings           from './settings';
import { parseSettings }  from '../reducers/settings';
import { resetShortcuts } from '../reducers/settings';
import { resetHolds }     from '../reducers/settings';
import { onKeyUp }        from '../reducers/keyboard';
import { importSettings } from '../reducers/settings';

const root = {
    root: function* () {
        yield all([
            takeLatest([onKeyDown().type], Quest.onKeyDown),
            takeLatest([onKeyUp().type], Quest.onKeyUp),
            takeLatest([restartQuest().type], Quest.onRestartQuest),
            takeLatest([nextShortcut().type], Quest.onNextShortcut),
            takeLatest([questFinished().type], Quest.onQuestFinished),
            takeLatest([newHistory().type], Highscore.onNewHistory),
            takeLatest([exportSettings().type], Settings.exportSettings),
            takeLatest([parseSettings().type], Settings.parseSettings),
            takeLatest([
                deleteShortcut().type,
                setNewShortcut().type,
                resetShortcuts().type,
                deleteHold().type,
                setNewHold().type,
                resetHolds().type,
                importSettings().type,
            ], Highscore.onShortcutChanged),
            takeEvery([error().type], Alerts.onAlert),
            takeLatest([
                REHYDRATE,
                tick().type,
            ], Clock.onTick),
        ]);
    },
};

export default root;
