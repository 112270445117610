import createStore             from './store';
import Home                    from './pages/Home';
import React                   from 'react';
import ReactDOM                from 'react-dom/client';
import reportWebVitals         from './reportWebVitals';
import { createBrowserRouter } from 'react-router-dom';
import { PersistGate }         from 'redux-persist/integration/react';
import { Provider }            from 'react-redux';
import { RouterProvider }      from 'react-router-dom';
import Alerts                  from 'components/Alerts';
import Settings                from 'components/Settings';

import styles from './styles.module.scss';

const root                 = ReactDOM.createRoot(document.getElementById('root'));
const { store, persistor } = createStore();
const router               = createBrowserRouter([
    {
        path:         '/',
        element:      <Home />,
        errorElement: <Home />,
    },
]);

root.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistor}
        >
            <Alerts />
            <div className={styles.main}>
                <RouterProvider router={router} />
            </div>
            <Settings />
        </PersistGate>
    </Provider>,
);

reportWebVitals();
