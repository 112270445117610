import { put }  from 'redux-saga/effects';
import { tick } from '../reducers/clock';
import Clock    from 'helper/Clock';

function* onTick() {
    yield Clock.sleep(1);
    yield put(tick());
}

const clock = {
    onTick,
};

export default clock;
