import { createSlice } from '@reduxjs/toolkit';
import _               from 'lodash';
import KeyTranslator   from 'helper/KeyTranslator';

const initialState = [];
const reducers     = {
    onKeyUp:    (state, action) => state.filter(key => key !== KeyTranslator.toStore(action.payload.key)),
    onKeyDown:  (state, action) => _.uniq([...state, KeyTranslator.toStore(action.payload.key)]),
    resetHolds: () => initialState,
};

const keyboardSlice = createSlice({
    name: 'keyboard',
    initialState,
    reducers,
});

export const { onKeyUp }    = keyboardSlice.actions;
export const { onKeyDown }  = keyboardSlice.actions;
export const { resetHolds } = keyboardSlice.actions;
export const getKeyboard    = (state) => state.keyboard;

export default keyboardSlice.reducer;
