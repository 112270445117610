export default class File {
    static download = (content, name) => {
        const link    = document.createElement('a');
        const file    = new Blob([content], { type: 'application/json' });
        link.href     = URL.createObjectURL(file);
        link.download = name;

        link.click();
    };

    static import = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsBinaryString(file);

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;
    });
}
