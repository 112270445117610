import React            from 'react';
import { restartQuest } from 'store/reducers/quest';
import { useDispatch }  from 'react-redux';
import PropTypes        from 'prop-types';

const Start = ({ questActive }) => {
    const dispatch  = useDispatch();
    const onRestart = () => dispatch(restartQuest());

    const getText = () => {
        if (!questActive) {
            return 'Start';
        }

        return 'Restart';
    };

    return (
        <button onClick={onRestart}>
            {getText()}
        </button>
    );
};

Start.propTypes = {
    questActive: PropTypes.bool,
};

export default Start;
