import React           from 'react';
import { useSelector } from 'react-redux';
import { getAlerts }   from 'store/reducers/alerts';
import Alert           from './Alert';

import styles from './styles.module.scss';

const Alerts = () => {
    const alerts = useSelector(getAlerts);

    return (
        <div className={styles.alerts}>
            {alerts.map((alert) => (
                <Alert
                    key={`alert-${alert.id}`}
                    alert={alert}
                />
            ))}
        </div>
    );
};

export default Alerts;
