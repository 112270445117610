import { createSlice } from '@reduxjs/toolkit';

const initialState = false;
const reducers     = {
    tick: (state) => !state,
};

const clockSlice = createSlice({
    name: 'clock',
    initialState,
    reducers,
});

export const { tick } = clockSlice.actions;
export const getClock = (state) => state.clock;

export default clockSlice.reducer;
