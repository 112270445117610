import { put }            from 'redux-saga/effects';
import { select }         from 'redux-saga/effects';
import { getSettings }    from '../reducers/settings';
import File               from 'helper/File';
import { importSettings } from '../reducers/settings';

function* exportSettings() {
    const settings = yield select(getSettings);

    File.download(JSON.stringify(settings), 'save.json');
}

function* parseSettings(action) {
    const result = yield File.import(action.payload.file);

    try {
        const settings = JSON.parse(result);

        yield put(importSettings(settings));
    } catch (exception) {
        console.error(exception);
    }
}

const settings = {
    exportSettings,
    parseSettings,
};

export default settings;
