import React                 from 'react';
import { useSelector }       from 'react-redux';
import { getQuestShortcuts } from 'store/reducers/quest';
import Shortcut              from '../Shortcut';

import styles from './styles.module.scss';

const Quests = () => {
    const quest = useSelector(getQuestShortcuts);

    return (
        <div className={styles.quests}>
            {quest.map((quest) => (
                <Shortcut
                    key={`quest-${quest.id}`}
                    id={quest.id}
                    keys={quest.keys}
                />
            ))}
        </div>
    );
};

export default Quests;
