import React            from 'react';
import KeyboardListener from './KeyboardListener';
import WindowListener   from './WindowListener';

const Listener = () => {
    return (
        <>
            <KeyboardListener />
            <WindowListener />
        </>
    );
};

export default Listener;
